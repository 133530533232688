<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center col-md-12">
      <CCard
        color="white"
        text-color="dark"
        class="text-center col-md-6"
        body-wrapper
      >
        <CAlert v-if="alert !== ''" color="danger">{{ alert }}</CAlert>
        <CAlert v-if="success !== ''" color="success">{{ success }}</CAlert>
        <img src="/logo.png" style="width: 80%" alt="" />
        <CForm>
          <br />
          <CInput v-model="username" type="text" placeholder="ชื่อผู้ใช้ ...">
            <template #prepend-content>
              <CIcon name="cil-user" />
            </template>
          </CInput>
          <CInput
            v-model="oldPassword"
            type="password"
            placeholder="รหัสผ่านเดิม ..."
          >
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
          </CInput>
          <CInput
            v-model="password"
            type="password"
            placeholder="รหัสผ่านใหม่ ..."
          >
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
          </CInput>

          <CRow>
            <CCol col="12">
              <CButton
                v-if="loadingButton === false"
                @click="resetPassword"
                block
                color="dark"
                class="px-4"
              >
                รีเซ็ตรหัสผ่าน
              </CButton>
              <CButton
                v-else-if="loadingButton === true"
                block
                color="dark"
                class="px-4"
                disabled
              >
                <CSpinner color="white" size="sm" /> รีเซ็ตรหัสผ่าน
              </CButton>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol col="12" class="text-center">
              <CButton block color="success" to="/cashier-login">
                เข้าสู่ระบบ
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import auth from '@/services/auth'

export default {
  name: 'Login',
  data() {
    return {
      loadingButton: false,
      username: '',
      oldPassword: '',
      password: '',
      alert: '',
      success: '',
    }
  },
  methods: {
    resetPassword() {
      this.loadingButton = true

      let data = {
        user: this.username,
        password: this.password,
        oldPassword: this.oldPassword,
      }

      console.log(data)

      auth
        .post('/auth/v1.0/' + this.uid + '/localuser/resetpassword', data)
        .then((res) => {
          console.log(res.data)

          if (res.data.error.code === 0) {
            let response = res.data.data
            this.alert = ''
            this.success = 'รีเซ็ตรหัสผ่านสำเร็จ'
          } else {
            this.success = ''
            this.alert = res.data.error.message
          }
          this.loadingButton = false
        })
    },
  },
}
</script>
